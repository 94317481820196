import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer, Event, SlotInfo } from "react-big-calendar";
import withDragAndDrop, { withDragAndDropProps } from "react-big-calendar/lib/addons/dragAndDrop";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { graphql } from "gatsby";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Container } from "@util/standard";
import { ModalBox } from "@global";
import { Query } from "@graphql-types";

interface Props {
  data: Query;
}

const CalendarFunction = ({ data }: Props) => {
  const { nodes: allCalendar } = data.allSanityCalendar;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEvent, setModalEvent] = useState<Event>();
  const [events, setEvents] = useState<Event[] | undefined>();

  const filterCalendar = allCalendar.map(calendar => {
    if (calendar == null) return null;
    const formatCalendar = {
      id: calendar.id,
      title: calendar.title as string | undefined,
      start: new Date(calendar.startDate) as Date | undefined,
      end: new Date(calendar.endDate) as Date | undefined,
      allDay: calendar.isAllday as boolean | undefined,
      description: calendar.calendarDescription,
      image: calendar.image,
    };
    return formatCalendar;
  });

  useEffect(() => {
    if (events) {
      const newCalendar = events.concat(filterCalendar as Event[]);
      setEvents(newCalendar);
      return;
    }
    setEvents(filterCalendar as Event[]);
  }, []);

  return (
    <Container margin="100px auto">
      <DnDCalendar
        defaultView="month"
        events={events}
        localizer={localizer}
        selectable={true}
        onSelectEvent={events => {
          setModalVisible(true);
          setModalEvent(events);
        }}
        showMultiDayTimes
        resizable
        style={{ height: "100vh", width: "90%", margin: "auto" }}
      />
      <ModalBox
        modalVisible={modalVisible}
        modalEvent={modalEvent}
        setModalVisible={setModalVisible}
      />
    </Container>
  );
};

const locales = {
  "en-US": enUS,
};

// The types here are `object`. Strongly consider making them better as removing `locales` caused a fatal error
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
//@ts-ignore
const DnDCalendar = withDragAndDrop(Calendar);

export default CalendarFunction;

export const query = graphql`
  query CalendarQuery {
    allSanityCalendar {
      nodes {
        _id
        _key
        calendarDescription {
          ...sanityBlockContent
        }
        category {
          _key
          category {
            ...sanityCategory
          }
        }
        startDate
        endDate
        id
        slug {
          current
          _key
        }
        title
        isAllday
        image {
          ...sanityImageWithMeta
        }
      }
    }
  }
`;
